import React, { useCallback, useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { DiningOptionBehavior, FulfillmentType, ShippingRate, useCartShippingRatesQuery, useSelectShippingRateMutation } from 'src/apollo/onlineOrdering';
import { useFulfillment } from 'src/public/components/online_ordering/FulfillmentContext';
import InputField from 'src/shared/components/common/form_input/InputField';
import { formatAddress } from 'src/shared/components/common/form_input/LocationInput';

import { ToggleInput } from 'shared/components/common/forms';
import { LoadingSpinner } from 'shared/components/common/loading_spinner/LoadingSpinner';
import PlacesAutocomplete from 'shared/components/common/location_search/PlacesAutocomplete';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { getEcommShippingFee } from 'public/components/default_template/online_ordering/cart/cartUtils';
import CheckoutSection from 'public/components/default_template/online_ordering/checkout/CheckoutSection';
import { useCart } from 'public/components/online_ordering/CartContext';
import { normalizeGoogleAddress } from 'public/components/online_ordering/addressUtils';

import { gPlacesAPIKey } from 'config';

import { AddressType, getDeliveryAddress, saveDeliveryAddress } from './shippingUtils';

export const ShippingSection = () => {
  const firstName = useWatch({ name: 'yourInfoFirstName' });
  const lastName = useWatch({ name: 'yourInfoLastName' });
  const { selectedLocation, restaurant, ooRestaurant } = useRestaurant();
  const [address, setAddress] = useState<AddressType | undefined>();
  const { cartGuid } = useCart();
  const { updateCartFulfillment } = useFulfillment();

  const onSetAddress = useCallback(async (address: AddressType) => {
    if(selectedLocation.externalId) {
      saveDeliveryAddress(address, selectedLocation.externalId);
    }
    const { name, ...deliveryInfo } = address;
    await updateCartFulfillment({ deliveryInfo, fulfillmentType: FulfillmentType.Asap, diningOptionBehavior: DiningOptionBehavior.TakeOut });
    setAddress(address);
  }, [updateCartFulfillment, selectedLocation]);

  useEffect(() => {
    const storedAddress = getDeliveryAddress(selectedLocation.externalId);
    if(storedAddress) {
      onSetAddress(storedAddress);
    }
  }, [selectedLocation, onSetAddress]);

  const onPlaceSelected = useCallback(async (place: google.maps.places.PlaceResult) => {
    const normalizedAddress = normalizeGoogleAddress(place);
    if(normalizedAddress) {
      const address = {
        name: `${firstName} ${lastName}`,
        ...normalizedAddress
      };

      onSetAddress(address);
    }
  }, [firstName, lastName, onSetAddress]);

  return (
    <CheckoutSection>
      <div className="shippingSection">
        <h2 className="checkoutSectionHeader">Shipping info</h2>
        <div className="addressInput" data-testid="shipping-address-input">
          <PlacesAutocomplete
            className="addressInputField"
            id="shipping-address-input-autocomplete"
            placeholder="Enter your shipping address"
            defaultValue={address
              ? formatAddress({
                address1: address.address1,
                city: address.city,
                state: address.state,
                zipcode: address.zipCode
              })
              : undefined}
            apiKey={gPlacesAPIKey}
            onPlaceSelected={onPlaceSelected}
            locationBias={selectedLocation?.lat && selectedLocation?.long ? { lat: selectedLocation.lat, long: selectedLocation.long } : undefined} />
        </div>
        {address ?
          <InputField label="Apartment, suite, etc. (optional)" id="address2" defaultValue={address?.address2 || undefined} onBlur={e => {
            if(e.target.value && address) {
              onSetAddress({
                ...address,
                address2: e.target.value
              });
            }
          }} />
          : null}
        {address && restaurant.id && ooRestaurant?.guid && cartGuid && <ShippingRateSelector siteGuid={restaurant.id} restaurantGuid={ooRestaurant.guid} cartGuid={cartGuid} address={address} />}
      </div>
    </CheckoutSection>
  );
};

const ShippingRateSelector = ({ siteGuid, restaurantGuid, cartGuid, address }: {siteGuid: string, restaurantGuid: string, cartGuid: string, address: AddressType}) => {
  const [setSelectedShippingRate] = useSelectShippingRateMutation();
  const { cart, refetchCart } = useCart();
  const [error, setError] = useState<string>();
  const [selectedRateGuid, setSelectedRateGuid] = useState<string | undefined>(
    getEcommShippingFee(cart)?.guid || undefined
  );

  const selectShippingRate = useCallback(async (selectedShippingRateGuid: string) => {
    setSelectedRateGuid(selectedShippingRateGuid);
    const { data } = await setSelectedShippingRate(
      { variables: { input: { siteGuid, cartGuid, restaurantGuid, selectedShippingRateGuid } } }
    );
    if(data?.setSelectedShippingRate?.__typename === 'EcommerceMutationErrorResponse') {
      setError(`Error setting shipping rate: ${data?.setSelectedShippingRate.message}`);
    }
    refetchCart();
  }, [setSelectedShippingRate, setSelectedRateGuid, cartGuid, siteGuid, restaurantGuid, refetchCart]);


  const { data, loading, refetch } = useCartShippingRatesQuery({
    variables: {
      input: {
        siteGuid,
        cartGuid,
        restaurantGuid: restaurantGuid
      }
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    // Force a refetch when the address changes. This assumes `updateCartFulfillment` has been called successfully
    setError(undefined);
    refetch();
  }, [address, refetch]);

  return (
    <div className="shippingRatesSection">
      {loading ?
        <div className="loader">
          <LoadingSpinner />
        </div> :
        <div className="shippingRates" role="radiogroup">
          {data?.getCartShippingRates.map((rate: ShippingRate) =>
            <ToggleInput
              name={rate.title}
              key={rate.shippingRateGuid}
              id={`shipping-rate-option-${rate.shippingRateGuid}`}
              type="radio"
              checked={selectedRateGuid === rate.shippingRateGuid}
              onChange={() => selectShippingRate(rate.shippingRateGuid)}>
              <div className="shippingRateOption">
                <div className="rate">
                  <div>{rate?.title}</div>
                  {rate?.amount && <div>{`${rate?.currency === 'USD' ? '$' : ''}${parseFloat(rate.amount).toFixed(2)}`}</div>}
                </div>
                <div className="estimatedDays">{rate.estimatedDays ? `Estimated delivery in ${rate?.estimatedDays} days` : rate.description || 'Delivery estimate not available'}</div>
              </div>
            </ToggleInput>)}
          {error && <div>{error}</div>}
        </div>}
    </div>
  );
};
