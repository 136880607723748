import React, { useMemo, useRef } from 'react';

import 'swiper/css';
import { Mousewheel, Navigation } from 'swiper/modules';
import { Swiper } from 'swiper/react';

import ChevronIcon from 'shared/components/common/carousel/ChevronIcon';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';
import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';

import ItemHistoryModalWrapper from './ItemHistoryModalWrapper';
import ItemModalWrapper from './ItemModalWrapper';
import { ReorderContextProvider } from './ReorderContext';
import { useItemSlides } from './useItemSlides';
import { useReorderableItemsQuery } from './useReorderableItemsQueryWrapper';

const ReorderItems = () => {
  const getColorFromTheme = useThemeColorScheme();
  const isMobile = useIsMobile(ScreenWidth.EXTRA_SMALL);
  const navigationPrevRef = useRef<HTMLButtonElement>(null);
  const navigationNextRef = useRef<HTMLButtonElement>(null);
  const slidesPerView = isMobile ? 1 : 2;

  const [arrowColor] = useMemo(() => {
    return getColorFromTheme(theme => [
      theme.colorScheme.icon.default
    ], [
      '#252525'
    ]);
  }
  , [getColorFromTheme]);

  const { loading, error } = useReorderableItemsQuery();
  const itemSlides = useItemSlides();

  if(!loading && !itemSlides.length || error) {
    return null;
  }

  return (
    <>
      <ItemModalWrapper />
      <AnimatedSection expanded={!loading && !!itemSlides.length}>
        <div className="paddedContentWrapper">
          <div className="reorderItems paddedContent" data-testid="reorderItems">
            <ItemHistoryModalWrapper />
            <div className="reorderItemsHeader">
              <h3 className="header">Order Again</h3>
              {itemSlides.length > slidesPerView &&
                <div className="arrows">
                  <button ref={navigationPrevRef} aria-label="Scroll left" className="arrow left">
                    <ChevronIcon color={arrowColor} />
                  </button>
                  <button ref={navigationNextRef} aria-label="Scroll right" className="arrow right">
                    <ChevronIcon color={arrowColor} />
                  </button>
                </div>}
            </div>
            <Swiper
              key={itemSlides.length} // Ensure Swiper is reinitialized when itemSlides changes
              className="reorderItemCards"
              slidesPerView={slidesPerView}
              spaceBetween={24}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
                disabledClass: 'disabledArrow'
              }}
              mousewheel={itemSlides.length > slidesPerView}
              modules={[Mousewheel, Navigation]}>
              {itemSlides}
            </Swiper>
          </div>
        </div>
      </AnimatedSection>
    </>
  );
};

const ContextualReorderItems = () =>
  <ReorderContextProvider>
    <ReorderItems />
  </ReorderContextProvider>;

export default ContextualReorderItems;
