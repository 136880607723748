import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useHistory, useParams, withRouter } from 'react-router';

import { ApolloQueryResult } from '@apollo/client';
import { LDFlagSet } from '@launchdarkly/node-server-sdk';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import isEmpty from 'lodash/isEmpty';

import { PromoBannersQuery, RestaurantQuery, usePromoBannersQuery, useRestaurantQuery } from 'src/apollo/onlineOrdering';
import {
  MenuConfig,
  OnlineOrderingConfig,
  RestaurantDataByDomainQueryHookResult,
  RestaurantDataByShortUrlQueryHookResult,
  RestaurantPage,
  useRestaurantDataByDomainQuery,
  useRestaurantDataByMgmtGuidQuery,
  useRestaurantDataByShortUrlQuery
} from 'src/apollo/sites';
import { reportError } from 'src/lib/js/clientError';
import { RequestContextProps } from 'src/lib/js/context';
import { siteHasIntlLocation } from 'src/lib/js/hooks/useIsIntlRestaurant';
import { getHost } from 'src/lib/js/utilities';
import ToastProduct from 'src/public/components/online_ordering/ToastProduct';
import { isToastLocalRequest } from 'src/public/js/siteUtilities';

import { MetaOverrideType, useDebug } from 'shared/components/common/debug/DebugContext';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import UhOh, { getUhOhPropsForError } from 'shared/components/uh_oh/UhOh';
import useDefaultSiteData, { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';
import useRestaurantSiteData from 'shared/js/hooks/useRestaurantSiteData';
import { SiteData, SiteRestaurantLocation } from 'shared/js/types';

import config from 'config';

import TrackRestaurant from './TrackRestaurant';

export type OORestaurant = RestaurantQuery['restaurantV2'] & { __typename: 'Restaurant' };
export type PromoBanners = PromoBannersQuery['promoBanners'] & { __typename: 'PromoBannerResponse' };
export type DiningOptions = RestaurantQuery['diningOptions'];
export type RestaurantSiteContent = NonNullable<SiteData>['content'] & { __typename: 'SiteContent' };
export type RestaurantConfig = SiteData['config'];

type DeletableRestaurantPage = RestaurantPage & { deleted?: boolean };

declare global {
  interface Window {
    __FLAGS_STATE__: LDFlagSet | undefined
  }
}

export type RestaurantContextType = {
  ooRestaurant?: OORestaurant;
  refetchOORestaurant: () => Promise<ApolloQueryResult<RestaurantQuery>>;
  ooPromoBanners?: PromoBanners;
  diningOptions?: DiningOptions;
  orderingDisabled?: boolean;
  restaurant: SiteData;
  sitePages?: RestaurantPage[] | null;
  locations?: SiteRestaurantLocation[] | null;
  selectedLocation: SiteRestaurantLocation;
  updateLocation: (guid: string, skipReplace?: boolean) => void;
  orderingUrl?: string | null;
  reservationsUrl?: string | null;
  phoneNumber?: string | null;
  pixels?: string[] | null;
  pixelsV2?: any | null;
  host?: string;
  toastProduct: ToastProduct;
  hasIntlLocation: boolean
};

type Params = {
  locationGuid?: string;
  slug?: string;
}

type WithRouterProps = RequestContextProps & ProviderProps
type BaseProps = RouterSubstituteProps & ProviderProps & { allowLocationSwitching: boolean } & DebugProps

type ProviderProps = (EditorTrueProps | EditorFalseProps) & EditorSharedProps

type EditorSharedProps = {
  siteRestaurant?: SiteData | null;
  siteRestaurantError?: RestaurantDataByDomainQueryHookResult['error'] | RestaurantDataByShortUrlQueryHookResult['error'];
  siteRestaurantLoading?: boolean;
  ooUsage?: RxDataUsage;
  sitesUsage?: RxDataUsage;
  shortUrl?: string;
}

type EditorTrueProps = {
  isEditor: true
  routeInfo: RouterSubstituteProps
  site: SiteData,
  sitePages?: RestaurantPage[] | null
}

type EditorFalseProps = {
  isEditor: false
  routeInfo?: null
  site?: null
  sitePages?: null
}

type RouterSubstituteProps = {
  path: string
  params: Params
  host: string
  history?: any
  staticContext?: any
}

type DebugProps = {
  overrides?: {
    menuPageConfigOverride?: MenuConfig,
    orderPageConfigOverride?: OnlineOrderingConfig,
    ooRestaurantOverride?: any,
    metaOverride?: MetaOverrideType,
    debugModeEnabled?: boolean
  }
}


const LOCATION_KEY = 'toast-rx-location';
export const RestaurantContext = createContext<RestaurantContextType | undefined>(undefined);

const RestaurantContextProvider = (props: React.PropsWithChildren<ProviderProps>) =>
  props.isEditor ?
    <RestaurantContextProviderWithoutRouter {...props} allowLocationSwitching={false} {...props.routeInfo} site={props.site} /> :
    <RestaurantContextProviderWithRouterAndDebug {...props} />;

const RestaurantContextProviderWithoutRouter = (props: React.PropsWithChildren<BaseProps>) => {
  return <RestaurantContextProviderBase {...props} {...props.routeInfo} siteRestaurant={props.site} />;
};


const RestaurantContextProviderWithRouterAndDebug = withRouter<any, React.ComponentType<WithRouterProps>>((props: WithRouterProps) => {
  const host = getHost(props.staticContext);
  const history = useHistory();
  const params = useParams<Params>();

  const { staticContext, sitesUsage = RxDataUsage.Required } = props;
  const { siteRestaurant, error: siteError, loading: siteLoading } = useRestaurantSiteData({
    staticContext,
    domainQueryHook: useRestaurantDataByDomainQuery,
    shortUrlQueryHook: useRestaurantDataByShortUrlQuery,
    mgmtGuidQueryHook: useRestaurantDataByMgmtGuidQuery,
    skipQuery: sitesUsage === RxDataUsage.None
  });

  const { data: defaultedSiteData, loading: defaultedSiteLoading, error: defaultedSiteError } = useDefaultSiteData({
    isPageQuery: false,
    staticContext,
    siteLoading,
    siteRestaurant,
    siteError
  });

  const { ooRestaurantOverride, metaOverride, menuPageConfigOverride, orderPageConfigOverride, debugModeEnabled } = useDebug();
  const overrides = { menuPageConfigOverride, orderPageConfigOverride, ooRestaurantOverride, metaOverride, debugModeEnabled };

  if(isToastLocalRequest(staticContext) && !defaultedSiteData && !defaultedSiteLoading) {
    return <NoMatch404 />;
  }

  return !defaultedSiteData && defaultedSiteLoading ?
    <LoadingSpinnerOverlay color="#2B4FB9" /> :
    <RestaurantContextProviderBase
      {...props} host={host} history={history} params={params} path={props.match.path} allowLocationSwitching={true}
      siteRestaurant={defaultedSiteData} siteRestaurantError={defaultedSiteError} siteRestaurantLoading={defaultedSiteLoading} overrides={overrides} />;
});

const RestaurantContextProviderBase = (props: React.PropsWithChildren<BaseProps>) => {
  const {
    siteRestaurant, siteRestaurantError, siteRestaurantLoading,
    sitesUsage = RxDataUsage.Required, ooUsage = RxDataUsage.None,
    history, params, host, path, allowLocationSwitching, children,
    isEditor, sitePages, staticContext
  } = props;
  // NOTE: DO NOT USE ANY react-router HOOKS in this base component.
  const ooClient = useOOClient();
  const filteredSitePages = useMemo(() => {
    return sitePages?.filter((page: DeletableRestaurantPage) => !page.deleted);
  }, [sitePages]);

  let currentLocation = siteRestaurant?.locations?.[0];
  const paramLocation = params.locationGuid;
  if(sitesUsage !== RxDataUsage.None) {
    if(paramLocation) {
      // If a user comes directly to a location link, we do want to 404 if that location doesn't exist
      currentLocation = siteRestaurant?.locations?.find((loc: SiteRestaurantLocation) => loc.externalId === paramLocation);
    } else if(params.slug) {
      currentLocation = siteRestaurant?.locations?.find((loc: SiteRestaurantLocation) => loc.shortUrl === decodeURIComponent(params.slug || ''));
    }
  }

  const [location, setLocation] = useState<SiteRestaurantLocation | undefined | null>(currentLocation);

  useEffect(() => {
    if(typeof window !== 'undefined' && sitesUsage !== RxDataUsage.None && !paramLocation && !params.slug) {
      const storedLocation = localStorage.getItem(LOCATION_KEY);
      const locationFromLocalStorage = storedLocation ? siteRestaurant?.locations?.find((loc: SiteRestaurantLocation) => loc.externalId === storedLocation) : undefined;
      if(locationFromLocalStorage) {
        setLocation(locationFromLocalStorage);
      } else {
        // the value in local storage isnt a valid GUID for this Rx
        localStorage.removeItem(LOCATION_KEY);
      }
    } else if(location?.externalId) {
      localStorage.setItem(LOCATION_KEY, location.externalId);
    }
  }, [siteRestaurant, sitesUsage, paramLocation, params.slug, location]);

  // A shortUrl is required to use OO and a lot of the associated Consumer App BFF queries.
  // It is required by the return type of this query.
  const skipOOQuery = ooUsage === RxDataUsage.None || !location?.externalId || !location?.shortUrl;
  const { data: restaurantData, error: ooError, loading: ooLoading, refetch } = useRestaurantQuery(
    {
      variables: { restaurantGuid: location?.externalId || '' },
      skip: skipOOQuery,
      client: ooClient
    }
  );

  const { data: promoData } = usePromoBannersQuery({
    variables: { restaurantGuid: location?.externalId || '' },
    skip: !location?.externalId || !!siteRestaurant?.config?.promoBannersDisabled,
    client: ooClient
  });

  // If updating the location on an OO page (i.e. the path includes a short URL),
  // this will only update the browser history stack if that location has a short URL.
  const updateLocation = useCallback((guid: string) => {
    if(allowLocationSwitching) {
      const newLoc = siteRestaurant?.locations?.find((loc: SiteRestaurantLocation) => loc.externalId === guid);
      if(newLoc) {
        localStorage.setItem(LOCATION_KEY, guid);
        setLocation(newLoc);
        const pathParams = {} as { slug?: string, rxGuid?: string };
        if(path.includes(':slug')) {
          if(newLoc.shortUrl) {
            pathParams.slug = newLoc.shortUrl;
          } else {
            console.error('Cannot change path for restaurant location');
            return;
          }
        }
        if(path.includes(':rxGuid')) {
          pathParams.rxGuid = newLoc.externalId;
        }

        if(!isEmpty(pathParams)) {
          history.replace(generatePath(path, pathParams));
        }
      }
    }
  }, [siteRestaurant, history, path, allowLocationSwitching]);

  const siteRestaurantWithOverrides = useMemo(() => {
    return props.overrides?.debugModeEnabled && siteRestaurant
      ? {
        ...siteRestaurant,
        meta: { ...siteRestaurant.meta, ...props.overrides.metaOverride },
        config: {
          ...siteRestaurant.config,
          ooConfig: { ...siteRestaurant.config.ooConfig, ...props.overrides.orderPageConfigOverride },
          menuConfig: { ...siteRestaurant.config.menuConfig, ...props.overrides.menuPageConfigOverride }
        }
      }
      : siteRestaurant;
  }, [props.overrides?.debugModeEnabled, props.overrides?.menuPageConfigOverride, props.overrides?.metaOverride, props.overrides?.orderPageConfigOverride, siteRestaurant]);

  const ooRestaurant = useMemo(() => {
    let restaurant = restaurantData?.restaurantV2 as OORestaurant;
    return restaurant && props.overrides?.debugModeEnabled ? { ...restaurant, ...props.overrides.ooRestaurantOverride } : restaurant;
  }, [props.overrides?.debugModeEnabled, props.overrides?.ooRestaurantOverride, restaurantData?.restaurantV2]);

  if(
    sitesUsage === RxDataUsage.Required && siteRestaurantError
    || ooUsage === RxDataUsage.Required && !ooLoading && (ooError || !restaurantData || restaurantData?.restaurantV2.__typename === 'GeneralError')
  ) {
    if(sitesUsage === RxDataUsage.Required && siteRestaurantError) {
      reportError('Error querying Sites API', siteRestaurantError);
    } else if(ooUsage === RxDataUsage.Required && ooError) {
      reportError('Error querying do-federated-gateway', ooError);
    } else if(ooUsage === RxDataUsage.Required && restaurantData) {
      reportError('Error returned from do-federated-gateway', undefined, restaurantData);
    } else if(ooUsage === RxDataUsage.Required && !location?.shortUrl) {
      return <UhOh {...{
        ...getUhOhPropsForError(null),
        subtitle: 'The "Toast Tab Page" setting is not set or published. \
          Set the "Toast Tab Page" <a href="https://preprod.eng.toasttab.com/restaurants/admin/basicinfo#restaurant_shortUrl">here</a>'
      }} />;
    }

    return <UhOh meta={siteRestaurant?.meta} siteTheme={siteRestaurant?.theme} {...getUhOhPropsForError(siteRestaurantError || ooError)} />;
  }

  if(!isEditor && !siteRestaurant && siteRestaurantLoading || !restaurantData && ooLoading) {
    return <LoadingSpinnerOverlay color="#2B4FB9" />;
  }

  if(!siteRestaurant || !location || !siteRestaurantWithOverrides) {
    let errorMessage: string | undefined = undefined;
    if(!location) {
      errorMessage = 'Please assign a location to this brand in order to use the page.';
    }
    return <NoMatch404 errorMessage={errorMessage} meta={siteRestaurant?.meta} siteTheme={siteRestaurant?.theme} />;
  }

  const bootstrapFlags = typeof window !== 'undefined' ? window.__FLAGS_STATE__ : undefined;

  const LDProvider = withLDProvider({
    clientSideID: config.ldClientId,
    context: { key: location.externalId },
    options: { bootstrap: bootstrapFlags }
  });


  const isOOBasic = siteRestaurant?.config?.hasFullCustomization === false;
  let toastProduct: ToastProduct = ToastProduct.OOPro;
  if(isToastLocalRequest(staticContext)) {
    toastProduct = ToastProduct.ToastLocal;
  } else if(isOOBasic) {
    toastProduct = ToastProduct.OOBasic;
  } else if(!siteRestaurant?.config?.isOnlineOrderingOnly) {
    toastProduct = ToastProduct.Sites;
  }

  const Component = () => {
    return (
      <RestaurantContext.Provider value={{
        ooRestaurant,
        refetchOORestaurant: refetch,
        ooPromoBanners: promoData?.promoBanners as PromoBanners,
        diningOptions: restaurantData?.diningOptions,
        orderingDisabled:
          !ooRestaurant?.onlineOrderingEnabled
          || restaurantData?.diningOptions?.every(
            option => !option.asapSchedule?.availableNow && (!option.futureSchedule?.dates.length || !option.futureSchedule?.dates.filter(date => date?.times?.length).length)
          ),
        restaurant: siteRestaurantWithOverrides,
        sitePages: filteredSitePages,
        locations: siteRestaurantWithOverrides.locations,
        selectedLocation: location,
        updateLocation,
        orderingUrl: location.onlineOrderingUrl || siteRestaurantWithOverrides.onlineOrderingUrl,
        reservationsUrl: location.reservationsUrl || siteRestaurantWithOverrides.reservationsUrl,
        phoneNumber: location.phoneNumber,
        pixels: siteRestaurantWithOverrides.pixels,
        pixelsV2: siteRestaurantWithOverrides.pixelsV2,
        host,
        toastProduct,
        hasIntlLocation: siteHasIntlLocation(siteRestaurant)
      }}>
        {children}
        {siteRestaurantLoading || ooLoading ? <LoadingSpinnerOverlay /> : null}
        <TrackRestaurant />
      </RestaurantContext.Provider>
    );
  };

  const LdWrappedComponent = LDProvider(Component);

  return isEditor ? <Component /> : <LdWrappedComponent />;
};

export const useRestaurant = () => {
  const context = useContext(RestaurantContext);
  if(!context) {
    throw new Error('useRestaurant must be used within a RestaurantContextProvider');
  }
  return context;
};

export const useOptionalRestaurant = () => {
  const context = useContext(RestaurantContext);
  return context;
};

export default RestaurantContextProvider;
