import React, { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';

import { DigitalSurface } from 'src/apollo/onlineOrdering';
import { useLocationSearchError } from 'src/shared/components/common/location_search/LocationSearchErrorContext';

import Image from 'shared/components/common/Image';
import ContextualLoadingSpinner, { LoadingSpinner } from 'shared/components/common/loading_spinner/LoadingSpinner';
import useGeocoder from 'shared/components/common/location_search/useGeocoder';

import { normalizeAddressString, normalizeGoogleAddress } from 'public/components/online_ordering/addressUtils';


const MINIMUM_AUTOCOMPLETE_CHARS = 3;
const LOCATION_BIAS_RADIUS = 10;
const PLACES_API_DEBOUNCE_MS = 1000;

const DEFAULT_COUNTRY_US = 'us';

type Props = {
  id: string;
  defaultValue?: string;
  placeholder?: string;
  autoFocus?: boolean;
  apiKey: string;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
  locationBias?: { lat: number, long: number },
  onValueChange?: (value: string) => void,
  normalized?: boolean,
  requireZip?: boolean,
  onError?: () => void,
  useCurrentLocation?: boolean;
  showGenericSpinner?: boolean;
  autoPromptCurrentLocation?: boolean;
  source?: DigitalSurface;
  className?: string;
  showAddress2?: boolean
}

type ContentsProps = {
  setCurrentLocationError: (error: string | undefined) => void;
} & Props

const PlacesAutocompleteContents = ({
  id,
  defaultValue,
  placeholder = 'Enter a city or address',
  apiKey,
  onPlaceSelected,
  autoFocus = false,
  locationBias,
  onValueChange = () => {},
  normalized = false,
  requireZip = false,
  onError,
  useCurrentLocation = false,
  showGenericSpinner = false,
  autoPromptCurrentLocation = false,
  source,
  className,
  showAddress2 = true,
  setCurrentLocationError
}: ContentsProps) => {
  const [isLoadingCurrentLocation, setIsLoadingCurrentLocation] = useState(false);

  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading
  } = usePlacesService({ apiKey: apiKey, libraries: ['places', 'geocoding'], debounce: PLACES_API_DEBOUNCE_MS });
  const geocoder = useGeocoder();

  const [searchText, setSearchText] = useState<string>();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const predictionsRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Don't close dropdown if click is inside the dropdown
      if(predictionsRef.current && predictionsRef.current.contains(event.target as Node)) {
        return;
      }
      setDropdownOpen(false);
    };
    if(dropdownOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [dropdownOpen, setDropdownOpen, predictionsRef]);

  const onSearchChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentLocationError(undefined);
    const value = event.target.value;
    onValueChange(value || '');
    setSearchText(event.target.value);

    // Only get autocomplete predictions if we have three characters to increase
    // the likelihood of a match
    if(value.length >= MINIMUM_AUTOCOMPLETE_CHARS) {
      setDropdownOpen(true);
      getPlacePredictions({
        input: value,
        types: ['geocode'],
        componentRestrictions: source === DigitalSurface.Local ? { country: DEFAULT_COUNTRY_US } : undefined,
        radius: locationBias ? LOCATION_BIAS_RADIUS : undefined,
        location: locationBias ? new google.maps.LatLng({ lat: locationBias.lat, lng: locationBias.long }) : undefined
      });
    }
  }, [onValueChange, getPlacePredictions, locationBias, setCurrentLocationError, source]);

  const searchOnGeocodedRequest = useCallback((request: google.maps.GeocoderRequest) => {
    geocoder().geocode(request, result => {
      const place = result?.[0];
      if(place) {
        if(source === DigitalSurface.Local) {
          const countryComponent = place.address_components?.find(component => component.types.includes('country'));
          if(countryComponent && countryComponent.short_name.toLowerCase() !== DEFAULT_COUNTRY_US) {
            setCurrentLocationError('Location must be within the United States');
            return;
          }
        }

        if(requireZip) {
          const normalizedAddress = normalizeGoogleAddress(place);
          if(normalizedAddress?.zipCode) {
            onPlaceSelected(place);
            if(!showAddress2) {
              // trim address2 from the search text to prevent address2 from being present in both the autocomplete input and the separate address2 input
              setSearchText(normalizeAddressString(normalizedAddress, showAddress2));
            }
          } else {
            // retry using the address input if a zip code is required and not found with the first request
            geocoder().geocode({ address: searchText }, retryResult => {
              if(retryResult?.[0]) {
                const normalizedAddress = normalizeGoogleAddress(retryResult[0]);
                if(normalizedAddress?.zipCode) {
                  onPlaceSelected(retryResult?.[0]);
                  if(!showAddress2) {
                    setSearchText(normalizeAddressString(normalizedAddress, showAddress2));
                  }
                } else {
                  onError?.();
                }
              }
            });
          }
        } else {
          onPlaceSelected(place);
        }

        if(normalized) {
          const normalizedAddress = normalizeGoogleAddress(place);
          if(normalizedAddress) {
            const addressString = normalizeAddressString(normalizedAddress);
            setSearchText(addressString);
          }
        }
      }
    });
  }, [geocoder, source, requireZip, normalized, setCurrentLocationError, onPlaceSelected, searchText, onError, showAddress2]);

  const onPredictionClick = useCallback((prediction: google.maps.places.AutocompletePrediction) => {
    setSearchText(`${prediction.structured_formatting.main_text}, ${prediction.structured_formatting.secondary_text}`);
    searchOnGeocodedRequest({ placeId: prediction.place_id });
    inputRef.current?.focus();
    setDropdownOpen(false);
    setCurrentLocationError(undefined);
  }, [searchOnGeocodedRequest, setCurrentLocationError]);

  const onCurrentLocationSuccess = useCallback((pos: GeolocationPosition) => {
    searchOnGeocodedRequest({ location: new google.maps.LatLng({ lat: pos.coords.latitude, lng: pos.coords.longitude }) });
    inputRef.current?.focus();
    setDropdownOpen(false);
    setIsLoadingCurrentLocation(false);
  }, [searchOnGeocodedRequest, inputRef, setDropdownOpen]);

  const onCurrentLocationError = useCallback(() => {
    setCurrentLocationError('POSITION_UNAVAILABLE');
    setDropdownOpen(false);
    setIsLoadingCurrentLocation(false);
  }, [setCurrentLocationError]);

  const onUseCurrentLocation = useCallback(() => {
    setIsLoadingCurrentLocation(true);
    setCurrentLocationError(undefined);
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onCurrentLocationSuccess, onCurrentLocationError, { maximumAge: Infinity, timeout: 10000 });
    } else {
      setCurrentLocationError('Geolocation services not available');
    }
  }, [onCurrentLocationError, onCurrentLocationSuccess, setCurrentLocationError]);

  const keyController = useCallback((event: React.KeyboardEvent) => {
    if(!predictionsRef.current || event.key !== 'ArrowDown' && event.key !== 'ArrowUp') {
      return;
    }
    const focusedElement = predictionsRef.current.querySelector('button:focus');
    let nextElement;

    if(inputRef.current === document.activeElement) {
      nextElement = predictionsRef.current.querySelector('button');
    } else if(focusedElement) {
      // get sibling above or below focused element.
      if(event.key === 'ArrowDown') {
        nextElement = focusedElement.nextElementSibling;
      } else if(event.key === 'ArrowUp') {
        nextElement = focusedElement.previousElementSibling;
      }
    } else {
      return;
    }

    if(nextElement instanceof HTMLButtonElement) {
      event.preventDefault();
      nextElement.focus();
    }
  }, []);

  const onDropdownBlur = useCallback((event: React.FocusEvent<HTMLDivElement>) => {
    if(event.currentTarget.contains(event.relatedTarget as Node)) {
      // blur happens inside the dropdown, don't collapse the dropdown.
      return;
    }
    // blur occurred elsewhere, collapse the dropdown.
    setDropdownOpen(false);
  }, []);

  // Auto-prompt on mount to get user's current location
  useEffect(() => {
    autoPromptCurrentLocation && onUseCurrentLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="autocompleteContainer" role="search">
      <div className="autocomplete" role="combobox" aria-expanded={dropdownOpen} aria-haspopup="listbox">
        <input
          id={id}
          data-testid="placesAutocomplete"
          className={classNames('input', className)}
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          value={searchText !== undefined ? searchText : defaultValue}
          onChange={onSearchChange}
          onKeyDown={keyController}
          aria-controls="locations-dropdown"
          aria-autocomplete="list"
          autoFocus={autoFocus}>
        </input>
        {!showGenericSpinner && (isLoadingCurrentLocation || isPlacePredictionsLoading) &&
          <div className="loadingSpinner">
            <ContextualLoadingSpinner size="24px" />
          </div>}
        {showGenericSpinner && (isLoadingCurrentLocation || isPlacePredictionsLoading) &&
          <div className="loadingSpinner">
            <LoadingSpinner size="24px" />
          </div>}
        {useCurrentLocation && !(isLoadingCurrentLocation || isPlacePredictionsLoading) &&
          <div className="currentLocation" onClick={e => {
            e.stopPropagation();
            setDropdownOpen(true);
          }}>
            <Image alt="Use my current location" src="icons/current-location.svg" />
          </div>}
        {dropdownOpen &&
          <div className="dropdown">
            <div className="predictions" ref={predictionsRef} id="locations-dropdown" role="listbox" aria-live="polite" onBlur={onDropdownBlur}>
              {useCurrentLocation &&
                <button
                  key="current-location"
                  className="prediction"
                  onClick={onUseCurrentLocation}
                  onKeyDown={keyController}
                  tabIndex={0}>
                  <div>Use my current location</div>
                  <Image alt="Use my current location" src="icons/current-location.svg" />
                </button>}
              {placePredictions.map(prediction =>
                <button
                  key={prediction.place_id}
                  className="prediction"
                  onClick={() => onPredictionClick(prediction)}
                  onKeyDown={keyController}
                  tabIndex={0}
                  aria-labelledby={prediction.place_id + '-label'}
                  role="option">
                  <div id={prediction.place_id + '-label'}>
                    {prediction.structured_formatting.main_text}, {prediction.structured_formatting.secondary_text}
                  </div>
                </button>)}
              <div className="attribution">
                <span>Powered by</span>
                <Image src="icons/google_on_white_hdpi.png" alt="Google" />
              </div>
            </div>
          </div>}
      </div>
    </div>
  );
};

const PlacesAutocomplete = ({ useCurrentLocation = false, ...props }: Props) => {
  if(useCurrentLocation) {
    return <PlacesAutocompleteWithErrorcontext useCurrentLocation {...props} />;
  }
  return <PlacesAutocompleteContents {...props} setCurrentLocationError={() => {}} />;
};

const PlacesAutocompleteWithErrorcontext = (props: Props) => {
  const { setCurrentLocationError } = useLocationSearchError();
  return <PlacesAutocompleteContents {...props} setCurrentLocationError={setCurrentLocationError} />;
};

export default PlacesAutocomplete;
