import React from 'react';

import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import PageWrapper from 'public/components/default_template/PageWrapper';
import EcommOrderConfirmation from 'public/components/default_template/online_ordering/confirmation/EcommOrderConfirmation';
import { getGlobalNavOverrideForOO } from 'public/components/online_ordering/globalNavUtils';

import { ContextualConfirmationPage } from './ConfirmationPage';

export const ConfirmationPageContent = ({ nav, footer } : { nav: React.ReactNode, footer?: React.ReactNode }) => {
  return (
    <div className="defaultTemplate">
      {nav}
      <div className="paddedSection whiteBackground">
        <main className="confirmation" id="main" tabIndex={0} aria-label="confirmation page">
          <EcommOrderConfirmation />
        </main>
      </div>
      {footer}
    </div>);
};

const RestaurantConfirmationPage = () => {
  const { restaurant } = useRestaurant();
  const navOverride = getGlobalNavOverrideForOO(restaurant);
  return <PageWrapper Component={ConfirmationPageContent} shouldShowPreviewBanner={false} navTypeOverride={navOverride} />;
};


const ConfirmationPage = () => <ContextualConfirmationPage><RestaurantConfirmationPage /></ContextualConfirmationPage>;
export default ConfirmationPage;
