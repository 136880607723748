import React, { FC, useCallback, useEffect } from 'react';


import { DeliveryInfo, SavedAddress } from 'src/apollo/onlineOrdering';
import { useExperiment } from 'src/shared/components/common/ab_testing/ABTestContext';
import { DropDownOption } from 'src/shared/components/common/dropdown/DropDownOption';

import DropDown from 'shared/components/common/dropdown';
import LoadingSpinnerOverlay from 'shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import DeliveryAddressInput from 'public/components/default_template/online_ordering/dining_options/DeliveryAddressInput';
import { Address2Input } from 'public/components/default_template/online_ordering/dining_options/delivery_address_selection/Address2Input';
import { useCustomer } from 'public/components/online_ordering/CustomerContextCommon';
import { useDelivery } from 'public/components/online_ordering/DeliveryContext';
import { formatDeliveryAddress } from 'public/components/online_ordering/addressUtils';

interface DeliveryAddressSelectionProps {
}

export const DeliveryAddressSelection: FC<DeliveryAddressSelectionProps> = () => {
  const { selectedLocation } = useRestaurant();
  const {
    validateDeliveryAddress, validateAllDeliveryAddresses, validDeliveryAddress, clearDeliveryAddress,
    isValidating, setSavedAddressUsed, setAddress2
  } = useDelivery();
  const { customer } = useCustomer();
  const { selectedVariant: deliveryAddressTest } = useExperiment('woo-delivery-addr-revision');

  const onSelectDeliveryAddress = useCallback(async (address: SavedAddress) => {
    const deliveryInfoInput = { ...address.deliveryInfo, __typename: undefined };
    if(deliveryAddressTest === 'test' && deliveryInfoInput.address2) {
      setAddress2(deliveryInfoInput.address2);
    }
    await validateDeliveryAddress(deliveryInfoInput, selectedLocation.externalId);
    setSavedAddressUsed(validDeliveryAddress ? true : false);
  }, [selectedLocation?.externalId, validateDeliveryAddress, validDeliveryAddress, setSavedAddressUsed, setAddress2, deliveryAddressTest]);

  useEffect(() => {
    // only use one of the saved addresses if a valid address isn't already set
    if(customer && customer.addresses && customer.addresses.length > 0 && !validDeliveryAddress) {
      validateAllDeliveryAddresses(customer.addresses, selectedLocation.externalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!customer || !customer.addresses || customer.addresses.length === 0) {
    return (
      <>
        <DeliveryAddressInput selectedLocation={selectedLocation} />
        {deliveryAddressTest === 'test' && <Address2Input />}
      </>);
  }

  const addressOptions: SavedAddress[] = [
    ...customer.addresses,
    // this option allows guests to enter a new address
    {
      guid: 'none',
      deliveryInfo: undefined as any
    }
  ];

  return (
    <>
      <div className="deliveryAddressSelection">
        <DropDown label={formattedDeliveryInfo(validDeliveryAddress, customer.addresses, deliveryAddressTest === 'test' ? false : true)} withBorder>
          {({ close }) => addressOptions.map(address => {
            return (
              <DropDownOption
                className="option"
                key={address.guid}
                onSelect={async e => {
                  e.preventDefault();
                  e.stopPropagation();
                  if(address.deliveryInfo) {
                    await onSelectDeliveryAddress(address);
                  } else {
                    clearDeliveryAddress();
                  }
                  close();
                }}>
                {formattedDeliveryInfo(address.deliveryInfo, customer?.addresses) }
              </DropDownOption>
            );
          })}
        </DropDown>
      </div>
      {!isValidating && !validDeliveryAddress &&
        <>
          <br />
          {/* Set showDefaultAddress=false to clear out the address input when going from a saved address to entering a new address.
          Otherwise, the address input will be pre-populated with the saved address */}
          <DeliveryAddressInput selectedLocation={selectedLocation} showDefaultAddress={false} />
        </>}
      {isValidating && <LoadingSpinnerOverlay withBorderRadius />}
      {deliveryAddressTest === 'test' && <Address2Input />}
    </>
  );
};

const formattedDeliveryInfo = (deliveryInfo: DeliveryInfo | undefined | null, addresses: SavedAddress[], showAddress2: boolean = true) => {
  if(!deliveryInfo) {
    return 'Enter a new address...';
  }

  const addressName = addresses
    .filter(address => formatDeliveryAddress(address.deliveryInfo, true) === formatDeliveryAddress(deliveryInfo, true))
    .map(address => address.name)[0];
  return (
    <>
      {addressName && <strong>{`${addressName} `}</strong>}{`${deliveryInfo.address1}${showAddress2 && deliveryInfo.address2 ? `, ${deliveryInfo.address2}` : ''}`}
    </>
  );
};
