import React, { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import classnames from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { useFulfillment } from 'src/public/components/online_ordering/FulfillmentContext';
import { usePayment } from 'src/public/components/online_ordering/PaymentContext';
import InputField from 'src/shared/components/common/form_input/InputField';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import AnimatedSection from 'public/components/default_template/online_ordering/checkout/AnimatedSection';
import { PreComputedTips } from 'public/components/online_ordering/types';

type Props = {
  tipOptions: PreComputedTips;
  selectedTipButton: string;
  onChange: (buttonId: string, tip: number) => void;
};

const TipSelector = (props: Props) => {
  const { tipOptions, selectedTipButton, onChange } = props;
  const { getValues } = useFormContext();
  const { setTipAmount, tipEnabled } = usePayment();
  const { selectedDiningOptionBehavior, uses3pd } = useFulfillment();
  const customTipAmount = useWatch({ name: 'custom-tip-amount', defaultValue: null });

  const isDelivery = selectedDiningOptionBehavior === DiningOptionBehavior.Delivery;
  const is3pdDelivery = isDelivery && uses3pd;

  // tip options coming back from the consumer app bff may contain duplicates
  const uniqueTipOptions = useMemo(() => [...new Map(tipOptions.filter(t => t).map(t => [t!.percent, t])).values()], [tipOptions]);

  const onClickCustomTip = () => {
    const tipValue = getValues('custom-tip-amount');
    if(tipValue === undefined || tipValue === null || tipValue === '') {
      onChange('custom-tip', 0.00);
    } else {
      const tipAmount = Number.parseFloat(tipValue);
      if(isNaN(tipAmount)) {
        onChange('custom-tip', 0.00);
      } else {
        onChange('custom-tip', Math.max(tipAmount, 0.00));
      }
    }
  };
  const onTipButtonClick = (buttonId: string, tipValue: number) => () => onChange(buttonId, tipValue || 0.00);

  const validateCustomTip = (value: string) => {
    if(selectedTipButton !== 'custom-tip' || value.trim() === '') {
      return true;
    }
    const tipAmount = Number.parseFloat(value);
    if(isNaN(tipAmount)) {
      return 'Please enter an amount';
    }
    if(tipAmount < 0) {
      return 'Cannot have negative tip.';
    }
    return true;
  };

  useEffect(() => {
    if(!tipEnabled) {
      setTipAmount(0);
    } else if(customTipAmount !== null && customTipAmount !== undefined) {
      onChange('custom-tip', Number(customTipAmount));
    } else if(is3pdDelivery) {
      const tip = uniqueTipOptions.find(tip => tip?.percent === 20);
      if(tip) {
        onChange(`tip-button-${tip.percent}`, tip.value);
      }
    }
  }, [onChange, customTipAmount, uniqueTipOptions, tipEnabled, setTipAmount, is3pdDelivery]);

  return (
    <div className="tipSelector">
      <h2 className="checkoutSectionHeader" id="tip-label">Add a {is3pdDelivery ? 'driver ' : ''}tip</h2>
      <div className="tipButtons">
        <div className="buttonGroup" role="radiogroup" aria-labelledby="tip-label">
          {uniqueTipOptions.map(tip => tip &&
            <button
              key={`tip-button-${tip.percent}`}
              type="button"
              data-testid={`tip-button-testid-${tip.percent}`}
              className={classnames('tipButton', selectedTipButton === `tip-button-${tip.percent}` && 'selected')}
              onClick={onTipButtonClick(`tip-button-${tip.percent}`, tip.value)}>
              <div>
                <div className="tipPercent">{tip.percent}%</div>
                <div className="tipAmount"><FormattedPrice value={tip.value} /></div>
              </div>
            </button>)}
          {tipOptions?.length && <button type="button" className={classnames('tipButton tipPercent', selectedTipButton === 'custom-tip' && 'selected')} onClick={onClickCustomTip}>Other</button>}
        </div>
        <AnimatedSection expanded={!tipOptions.length || selectedTipButton === 'custom-tip'}>
          <div className="customTipInput">
            <InputField id="custom-tip-amount" label="Tip Amount" type="number" step="any" min="0" validate={selectedTipButton === 'custom-tip' ? validateCustomTip : undefined} />
          </div>
        </AnimatedSection>
      </div>
    </div>
  );
};

export default TipSelector;
