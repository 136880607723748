import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams, withRouter } from 'react-router';

import { ApolloProvider } from '@apollo/client';
import { useEditor } from '@toasttab/sites-components';
import classnames from 'classnames';

import { DiningOptionBehavior } from 'src/apollo/onlineOrdering';
import { HeroContentType, LogoSizeOption, MenuFormatConfig, SiteContentDataFragment } from 'src/apollo/sites';
import { RequestContextProps } from 'src/lib/js/context';
import useElementInBounds from 'src/lib/js/hooks/useElementInBounds';
import useScrollDirection from 'src/lib/js/hooks/useScrollDirection';
import { getHost } from 'src/lib/js/utilities';
import LoadingSpinnerOverlay from 'src/shared/components/common/loading_spinner/LoadingSpinnerOverlay';
import { useOOClient } from 'src/shared/components/common/oo_client_provider/OOClientProvider';
import { normalizeHtmlId } from 'src/shared/js/normalizeUtils';


import GlobalNav from 'shared/components/common/nav/Nav';
import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { ScreenWidth, useIsMobile } from 'shared/js/utils/WindowContext';

import Footer from 'public/components/default_template/footer';
import StickyFooter from 'public/components/default_template/footer/sticky_footer/StickyFooter';
import MenuPageHeader from 'public/components/default_template/menu_header/MenuPageHeader';
import MenuNav, { useMenuNav } from 'public/components/default_template/menu_nav/MenuNav';
import { scrollToRef } from 'public/components/default_template/menu_nav/menuNavScrollUtils';
import { getTemplateClass } from 'public/components/default_template/menu_section/MenuItemCard';
import MenuSection from 'public/components/default_template/menu_section/MenuSection';
import PageMeta from 'public/components/default_template/meta/PageMeta';
import Nav from 'public/components/default_template/nav/Nav';
import { CartContextProvider } from 'public/components/online_ordering/CartContext';
import { DeliveryContextProvider } from 'public/components/online_ordering/DeliveryContext';
import { EditorFulfillmentContextProvider, FulfillmentContextProvider } from 'public/components/online_ordering/FulfillmentContext';
import OffersContextProvider from 'public/components/online_ordering/OffersContext';
import { useOOMenus } from 'public/components/online_ordering/useOOMenu';

import { MenuHeader } from './MenuHeader';
import { DebugPanel } from './debug/DebugPanel';

type SiteContent = SiteContentDataFragment['content'] & { __typename?: 'SiteContent' };

type Params = {
  menuGuid?: string;
};

type Props = {
  host?: string;
  params?: Params;
};

const ParamDetectingMenuPage = ({ staticContext }: RequestContextProps) => {
  const host = getHost(staticContext);
  const params = useParams<Params>();
  return <MenuPage host={host} params={params} />;
};

const WrappedMenuPage = ({ host = '', params = {} }: Props) => {
  const { selectedLocation, ooRestaurant, restaurant, locations } = useRestaurant();
  const { scrollDirection } = useScrollDirection();
  const { useEditableRef } = useEditor();
  const navRef = useRef<HTMLDivElement>(null);
  const { editableRef } = useEditableRef<HTMLDivElement>({ name: 'menu', displayName: 'Menu', actions: [], schema: { fields: [] }, skipRef: navRef });
  const [scrollNavRef, inBounds] = useElementInBounds<HTMLDivElement>({ maxScrollY: 1 });
  const isMobile = useIsMobile(ScreenWidth.SMALL);
  const { isEditor } = useEditor();
  const hasScrolledToParam = useRef(false);
  // TODO: Upcoming work will make the menu nav type configurable.  Until then, we'll hard-code it as necessary.
  // https://toasttab.atlassian.net/browse/WOO-772
  const sideBySide = false;

  const [isMapOrModalOpen, setIsMapOrModalOpen] = useState(false);

  useEffect(() => {
    if(typeof window !== 'undefined') {
      require('smoothscroll-polyfill').polyfill();
    }
  }, []);

  // Get all the channel Menus if they exist
  const { menus: channelMenus, loading: channelMenusLoading, error: channelsError } = useOOMenus({
    respectAvailability: false,
    channelGuid: selectedLocation?.viewOnlyMenuChannelGuid || null,
    skipPopularItems: true,
    skip: !selectedLocation?.viewOnlyMenuChannelGuid
  });

  const channelsQueryComplete = (channelMenus || channelsError) && !channelMenusLoading;
  const channelMenusIsEmpty = !!channelMenus && channelMenus?.length === 1 && channelMenus[0]?.groups?.length === 0;

  // Always get the OO menus
  const { menus: ooMenus } = useOOMenus({
    respectAvailability: false,
    channelGuid: null,
    skipPopularItems: true,
    skip: channelMenusLoading || !!selectedLocation?.viewOnlyMenuChannelGuid && channelsQueryComplete && !channelMenusIsEmpty && !channelsError
  });

  // If there was an error with MenusV3
  const menus = channelsQueryComplete && (channelMenusIsEmpty || channelsError) ? ooMenus : channelMenus;

  const {
    selectedMenuIndex,
    setSelectedMenuIndex,
    scrolledMenuIndex,
    setScrolledMenuIndex,
    selectedGuid,
    setSelectedGuid,
    scrolledGuid,
    setScrolledGuid,
    refMenuPages
  } = useMenuNav({ menus, page: '/menu' });

  const pageNode = useCallback((node: any) => {
    if(node && params.menuGuid && !hasScrolledToParam.current) {
      const menuIndex = refMenuPages?.findIndex(menu => menu.guid === params.menuGuid);
      const pageRef = refMenuPages[menuIndex]?.ref;
      if(menuIndex > -1 && pageRef) {
        setSelectedMenuIndex(menuIndex);
        scrollToRef(pageRef);
        hasScrolledToParam.current = true;
      }
    }
  }, [params.menuGuid, setSelectedMenuIndex, refMenuPages]);

  const { title, description } = useMemo(() => {
    // Source the location-specific page seo meta override if it exists
    const title = selectedLocation?.menuPageConfigOverride?.seoMeta?.title || restaurant.config.ooConfig?.seoMeta?.title || 'Menu';
    const description = selectedLocation?.menuPageConfigOverride?.seoMeta?.description || restaurant.config.ooConfig?.seoMeta?.description ||
    `Official menu for ${selectedLocation.name || restaurant.name}. See the most up-to-date menus with photos and descriptions.`;
    return { title, description };
  }, [restaurant?.name,
    selectedLocation.name,
    selectedLocation?.menuPageConfigOverride?.seoMeta?.description,
    restaurant.config.ooConfig?.seoMeta?.description,
    selectedLocation?.menuPageConfigOverride?.seoMeta?.title,
    restaurant.config.ooConfig?.seoMeta?.title]);

  if(!host || !selectedLocation) {
    return <NoMatch404 meta={restaurant?.meta} siteTheme={restaurant?.theme} />;
  }
  if(!menus) {
    return <LoadingSpinnerOverlay />;
  }
  if(!ooRestaurant?.shortUrl) {
    return <NoMatch404 meta={restaurant?.meta} siteTheme={restaurant?.theme} />;
  }
  if(!restaurant) {
    return <NoMatch404 />;
  }

  const { content } = restaurant;
  const { meta, theme } = restaurant;

  if(!content || !refMenuPages || !menus) {
    return <NoMatch404 meta={meta} siteTheme={theme} />;
  }

  const ooFormatConfig: MenuFormatConfig | undefined | null = restaurant.config.ooConfig?.format;

  const { primaryCta, nonPrimaryCtas } = content as SiteContent;
  const hasHero = !!restaurant.config.ooConfig?.heroImage?.src;
  const usingSecondaryNav = inBounds;
  const navOpen = scrollDirection === 'up' && !isMobile && usingSecondaryNav;
  const menuFormatConfig: MenuFormatConfig | undefined | null = restaurant.config.menuConfig?.format;


  return (
    <>
      <PageMeta title={title} description={description} />
      <div className="defaultTemplate" ref={pageNode} data-testid={'menu-page'}>
        <Nav
          withShadow={!usingSecondaryNav}
          logoSrc={meta.icon}
          logoObject={meta.iconObject}
          logoSize={LogoSizeOption.Standard}
          navType="stickyNav"
          primaryCta={primaryCta}
          nonPrimaryCtas={nonPrimaryCtas}
          className={classnames('globalNav', 'noOrder', { collapsed: !navOpen && usingSecondaryNav })}
          shouldShowPreviewBanner={true} />
        <MenuPageHeader heading="Menu" heroConfig={restaurant.config.ooConfig as HeroContentType} />
        <div className="pageContent" ref={scrollNavRef} role="main" id="main" aria-label="menu">
          <div ref={editableRef}>
            <div className={classnames('navAndMenu', { sideBySide })}>
              <GlobalNav navType="stickyNav"
                shouldShowPreviewBanner={true}
                className={classnames('secondaryNav', 'noOrder', {
                  withoutHero: !hasHero,
                  withShadow: usingSecondaryNav,
                  globalNavOpen: navOpen || !usingSecondaryNav,
                  noLocationSelector: locations?.length === 1,
                  sideBySide
                })}>
                <MenuNav
                  menus={refMenuPages}
                  selectedGroupGuid={selectedGuid}
                  setSelectedGroupGuid={setSelectedGuid}
                  selectedMenuIndex={selectedMenuIndex}
                  setSelectedMenuIndex={setSelectedMenuIndex}
                  menuConfig={menuFormatConfig}
                  navRef={navRef} />
              </GlobalNav>
              <div className="menuSections">
                <div className={classnames('menuSectionWrapper', getTemplateClass(menuFormatConfig?.template), { columns: menuFormatConfig?.columns })}>
                  {refMenuPages.map((page, pageIndex) =>
                    <div
                      aria-labelledby={`${normalizeHtmlId(page.name)}-tab`}
                      role="tabpanel"
                      className={classnames('menuWrapper', 'paddedContentWrapper', { sideBySide })}
                      key={page.name} ref={page.ref}
                      id={`menu-${page.guid}`}>
                      <div className="menu paddedContent">
                        <div className="headerWrapper">
                          <MenuHeader
                            header={refMenuPages.length > 1 ? page.name : null} />
                        </div>
                        {page.groups.map(group =>
                          <MenuSection
                            key={group.guid}
                            setIsMapOrModalOpen={setIsMapOrModalOpen}
                            isMapOrModalOpen={isMapOrModalOpen}
                            description={group.description}
                            isReadOnly
                            onVisible={() => {
                              const newSection = group.guid || group.name;
                              if(scrolledGuid === selectedGuid) {
                                setSelectedGuid(newSection);
                              }
                              if(selectedMenuIndex === scrolledMenuIndex) {
                                setSelectedMenuIndex(pageIndex);
                                setSelectedGuid(newSection);
                              }
                              setScrolledGuid(newSection);
                              setScrolledMenuIndex(pageIndex);
                            }}
                            ref={group.ref}
                            header={page.groups.length < 2 && group.name.toLowerCase() === page.name.toLowerCase() ? undefined : group.name}
                            orientation={restaurant.config.ooConfig?.cardOrientation}
                            menuItems={group.items}
                            menuConfig={restaurant.config.isMenuOnly ? { format: ooFormatConfig, colors: restaurant.config.ooConfig?.colors } : restaurant.config.menuConfig} />)}
                        {pageIndex !== refMenuPages.length - 1 ? <hr className="sectionSeparator" /> : null}
                      </div>
                    </div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <StickyFooter primaryCta={primaryCta} />
      {!isEditor ? <DebugPanel menuPage /> : null}
    </>
  );
};

export const MenuPage = (props: Props) => {
  const ooClient = useOOClient();
  const { isEditor } = useEditor();

  return (
    <ApolloProvider client={ooClient}>
      <CartContextProvider>
        <DeliveryContextProvider>
          <OffersContextProvider>
            {isEditor
              ?
              <EditorFulfillmentContextProvider diningOptionBehavior={DiningOptionBehavior.TakeOut}>
                <WrappedMenuPage {...props} />
              </EditorFulfillmentContextProvider>
              :
              <FulfillmentContextProvider>
                <WrappedMenuPage {...props} />
              </FulfillmentContextProvider>}
          </OffersContextProvider>
        </DeliveryContextProvider>
      </CartContextProvider>
    </ApolloProvider>
  );
};

export default withRouter<RequestContextProps, React.ComponentType<RequestContextProps>>(ParamDetectingMenuPage);
