import React, { PropsWithChildren, useMemo } from 'react';

import classnames from 'classnames';

import { MenuConfig } from 'src/apollo/sites';

import { useRestaurant } from 'shared/components/common/restaurant_context/RestaurantContext';

import { overrideConfigBasedOnTemplate } from 'public/components/default_template/menu_section/menuStylingOverrides';
import { DEFAULT_COLORS } from 'public/components/default_template/meta/StyleMeta';
import { useThemeColorScheme } from 'public/components/default_template/meta/useTheme';

type Props = {
  onClick?: () => void;
  testId?: string;
}

const BaseCard = (props: PropsWithChildren<Props>) => {
  const { restaurant: { config } } = useRestaurant();
  const getColorFromTheme = useThemeColorScheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const templateOverrides = useMemo(() => overrideConfigBasedOnTemplate(config.ooConfig as MenuConfig, config), [config, config.ooConfig]);

  const [backgroundColor, textColor, borderColor] = getColorFromTheme(theme => {
    return [
      theme.colorOverrides?.onlineOrderingMenu?.cardBackground ?? theme.colorScheme.surface.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.itemTitle ?? theme.colorScheme.text.secondary,
      theme.colorOverrides?.onlineOrderingMenu?.cardBorder ?? theme.colorScheme.border.default
    ];
  },
  [
    config.ooConfig?.colors?.background ?? DEFAULT_COLORS.background ?? '#FFFFFF',
    config.ooConfig?.colors?.primaryText ?? DEFAULT_COLORS.text,
    templateOverrides?.borderColor
  ]);

  return (
    <button
      data-testid={props.testId}
      onClick={props.onClick}
      className={classnames('reorderItemCard',
        templateOverrides.borderStroke,
        templateOverrides?.roundedCorner,
        templateOverrides?.dropShadow)}
      style={{ borderColor, backgroundColor, color: textColor }}>
      {props.children}
    </button>
  );
};

export default BaseCard;
