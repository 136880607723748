import React, { useMemo } from 'react';

import { formatImageURL } from 'src/lib/js/utilities';
import { getMenuItemSrcSet } from 'src/public/components/default_template/menu_section/MenuItemCard';
import Image from 'src/shared/components/common/Image';
import FormattedPrice from 'src/shared/components/common/price/FormattedPrice';

import BaseCard from './BaseCard';
import { useReorder } from './ReorderContext';
import type { Item } from './ReorderContext';

type Props = {
  item: Item
  testId?: string
}

const ItemCard = (props: Props) => {
  const { setReorderItem } = useReorder();
  const { item } = props;
  const { selection } = props.item;

  const { imgSrc, imgSrcSet } = useMemo(() => {
    const imgSrc = item.imageUrls?.medium || item.imageUrls?.small || item.imageUrls?.large || item.imageUrls?.xs;
    return {
      imgSrc: imgSrc && formatImageURL(imgSrc),
      imgSrcSet: item.imageUrls && getMenuItemSrcSet(item.imageUrls)
    };
  }, [item.imageUrls]);

  const modifierText = useMemo(() => [...new Set(selection.modifiers?.map(modifier => modifier?.name))]
    .filter(name => Boolean(name))
    .join(' · '), [selection.modifiers]);
  const price = selection.preDiscountPrice || 0;

  return (
    <BaseCard
      data-testid={props.testId}
      onClick={() => setReorderItem(item) }>
      <div className="itemInfo" data-testid={`item-${selection.guid}`}>
        <div className="itemText" data-testid="item-text">{selection.name}</div>
        <div className="modifierText" data-testid="item-modifier-text">{modifierText}</div>
        <div className="itemPrice" data-testid="item-price">{price ? <FormattedPrice value={price} /> : null}</div>
      </div>
      {imgSrc && imgSrcSet && <Image src={imgSrc} srcSet={imgSrcSet} className="itemImage" alt="" data-testid={item.selection.name + '-image'} />}
    </BaseCard>
  );
};

export default ItemCard;
