import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import urljoin from 'url-join';

import { Domain, Redirect } from 'src/apollo/sites';
import { RequestContext } from 'src/lib/js/context';
import { getHost } from 'src/lib/js/utilities';

import RedirectWithStatus from 'shared/components/common/RedirectWithStatus';
import NoMatch404 from 'shared/components/no_match_404/NoMatch404';
import { RxDataUsage } from 'shared/js/hooks/useDefaultSiteData';
import { SiteData } from 'shared/js/types';

import AccountPage from 'public/components/default_template/AccountPage';
import CheckoutPage from 'public/components/default_template/CheckoutPage';
import ConfirmationPage from 'public/components/default_template/ConfirmationPage';
import OrderPage from 'public/components/default_template/OrderPage';
import { SiftContextProvider } from 'public/components/default_template/meta/sift/SiftContext';
import { CustomerContextProviderWrapper } from 'public/components/online_ordering/CustomerContextWrapper';
import AppRedirect from 'public/components/pages/app_redirect/AppRedirect';
import LocationSelectionPage from 'public/components/pages/location_selection_page/LocationSelectionPage';
import MapPage from 'public/components/pages/map_page/MapPage';
import PageShell from 'public/components/pages/page_shell';
import AdyenPage from 'public/components/pages/payment_page/AdyenPage';
import { getSiteMgmtGroupGuid, getSiteRef, getSiteShortUrl, isToastOrderLocationsRequest, TOASTTAB_BOO_LOCATIONS_PATH, TOASTTAB_BOO_PATH } from 'public/js/siteUtilities';

import { resources } from 'config';

import useSitePagesData from './useSitePagesData';

const GENERIC_SITE_SUFFIX = 'toast.site';
const LEGACY_GENERIC_SITE_SUFFIX = 'toast.ventures';
const isToastVenturesDomain = (d: Domain) => d.domain.endsWith(LEGACY_GENERIC_SITE_SUFFIX);
const isGenericSite = (d: Domain) => d.domain.endsWith(GENERIC_SITE_SUFFIX);
const isWwwSubDomain = (d: Domain) => d.domain.startsWith('www.');
const getDomainPriority = (d: Domain) => {
  if(isToastVenturesDomain(d)) return 3;
  if(isGenericSite(d)) return 2;
  if(isWwwSubDomain(d)) return 1;
  return 0;
};
const sortDomainsByPriority = (a: Domain, b: Domain) => {
  return getDomainPriority(a) - getDomainPriority(b);
};

type OrderRoutesProps = {
  staticContext?: RequestContext,
  sitesUsage: RxDataUsage,
  rootPathPatternPrefix?: string,
  orderPathPattern?: string,
  orderPath?: string,
  checkoutPathPatternPrefix?: string,
  checkoutPathPrefix?: string,
  confirmPathPatternPrefix?: string,
  confirmPathPrefix?: string,
  locationsPathPatternPrefix?: string
};


// A generic domain should not be considered a custom domain for OO.
// Custom domains for a website that is out of preview mode will contain its generic domain.
const getCustomDomains = (siteRestaurant: SiteData) => {
  if(siteRestaurant.config.isOnlineOrderingOnly) {
    return siteRestaurant.domainsV2?.filter(d =>
      !d.pendingVerification
      && !d.domain.endsWith(LEGACY_GENERIC_SITE_SUFFIX)
      && !d.domain.endsWith(GENERIC_SITE_SUFFIX));
  } else {
    return siteRestaurant.domainsV2?.filter(d =>
      // Filter out the domain if it's pending verification
      !d.pendingVerification
      // Filter out the domain if it's generic and we're in preview mode
      && !((d.domain.endsWith(LEGACY_GENERIC_SITE_SUFFIX) || d.domain.endsWith(GENERIC_SITE_SUFFIX))
            && siteRestaurant.config?.previewMode === true));
  }
};

export const OrderRoutes = (
  {
    staticContext,
    sitesUsage,
    rootPathPatternPrefix = '',
    orderPathPattern = '/order/:slug',
    orderPath,
    checkoutPathPatternPrefix = '',
    checkoutPathPrefix,
    confirmPathPatternPrefix = '',
    confirmPathPrefix,
    locationsPathPatternPrefix = ''
  }: OrderRoutesProps
) => {
  const host = getHost(staticContext);
  const shortUrl = getSiteShortUrl(staticContext);
  const mgmtGroupGuid = getSiteMgmtGroupGuid(staticContext);
  const siteRef = getSiteRef(staticContext);
  const toastOrderLocationsRequest = isToastOrderLocationsRequest(staticContext);
  const { search } = useLocation();

  const { sitePagesData: siteRestaurant, loading } = useSitePagesData(staticContext);

  if(loading) {
    return null;
  }

  if(!host || !siteRestaurant) {
    return <NoMatch404 meta={siteRestaurant?.meta} siteTheme={siteRestaurant?.theme} />;
  }

  const hasMultiLocations = (siteRestaurant.locations?.length || 0) > 1;
  const redirects = siteRestaurant.redirects as Array<Redirect>;
  const primaryColor = siteRestaurant.meta?.primaryColor;
  const theme = siteRestaurant.theme;

  // These domains are used to create redirects from OOv4. Only redirect to a *.toast.site domain if the Website is not in preview mode.
  // Sort the domains to prefer any non-toast.site domains.
  const customDomains = getCustomDomains(siteRestaurant)?.sort(sortDomainsByPriority);
  // For OOPro & Sites customers with a custom domain configured, redirect order.toasttab.com requests to their custom domain.
  // If they have multiple locations, redirect to the order page for the specific location.
  const redirectToCustomDomain = host === resources.toastOrderHost
    && siteRestaurant.config.hasFullCustomization !== false
    && customDomains?.length
    && !siteRestaurant.config.previewMode;

  // Create urls for the redirect. If the Site has a domain linked to the /order page, use that. Otherwise, use
  // the first custom domain. Append /order to navigate to the order page for Sites without a domain linked directly to the order page.
  // Append /order/<shortUrl> to create a url for the order page of a specific location.
  let customLocationSelectionUrl = '';
  let customOrderUrl = '';
  let customOrderLocationUrl = '';
  if(redirectToCustomDomain) {
    const orderDomain = customDomains?.find(d => d.pageRoute === '/order')?.domain;
    if(orderDomain) {
      // Websites with a (sub)domain that is mapped to /order
      customLocationSelectionUrl = `https://${orderDomain}`;
      customOrderUrl = customLocationSelectionUrl;
      customOrderLocationUrl = urljoin(customOrderUrl, 'order', shortUrl);
    } else if(siteRestaurant.config.isOnlineOrderingOnly) {
      // OO Pro
      customLocationSelectionUrl = `https://${customDomains?.[0]?.domain}`;
      customOrderUrl = customLocationSelectionUrl;
      customOrderLocationUrl = urljoin(customOrderUrl, 'order', shortUrl);
    } else {
      // Websites
      customLocationSelectionUrl = urljoin(`https://${customDomains?.[0]?.domain}`, 'order');
      customOrderUrl = customLocationSelectionUrl;
      customOrderLocationUrl = urljoin(customOrderUrl, shortUrl);
    }
    // Append the search query to the urls to preserve the query parameters when redirecting
    customLocationSelectionUrl = urljoin(customLocationSelectionUrl, search);
    customOrderUrl = urljoin(customOrderUrl, search);
    customOrderLocationUrl = urljoin(customOrderLocationUrl, search);
  }

  const pageShellProps = {
    sitesUsage, ooUsage: RxDataUsage.Required, orderPath, orderPathPattern, checkoutPathPrefix, confirmPathPrefix,
    homePath: toastOrderLocationsRequest ? `${TOASTTAB_BOO_LOCATIONS_PATH}/${mgmtGroupGuid}/${siteRef}` : orderPath,
    isOO: true
  };
  const locationPageAtRoot = hasMultiLocations && !locationsPathPatternPrefix;

  return (
    <CustomerContextProviderWrapper>
      <SiftContextProvider>
        <Switch>
          {redirectToCustomDomain
          // Using a 302 instead of 301 in case of a downsell, which would remove this redirect
            ? <Route exact path={orderPathPattern} component={() => <RedirectWithStatus to={hasMultiLocations ? customOrderLocationUrl : customOrderUrl} status={302} />} />
            : null}
          {redirectToCustomDomain && locationsPathPatternPrefix
          // Using a 302 instead of 301 in case of a downsell, which would remove this redirect
            ? <Route exact path={`${locationsPathPatternPrefix}/`} component={() => <RedirectWithStatus to={customLocationSelectionUrl} status={302} />} />
            : null}
          {locationsPathPatternPrefix ?
            <Route exact path={`${locationsPathPatternPrefix}/`} key="/"
              component={() => <PageShell titleTag="Location Selection" {...pageShellProps} ooUsage={RxDataUsage.Optional}><LocationSelectionPage /></PageShell>} /> :
            null}
          {locationsPathPatternPrefix ?
            <Route exact path={`${locationsPathPatternPrefix}/brandedAppRedirect`} key="/locationsBrandedAppRedirect"
              component={() => <PageShell {...pageShellProps} ooUsage={RxDataUsage.Optional}><AppRedirect /></PageShell>} /> :
            null}
          {redirects?.map(({ from, to, status }) => <Route key={from} exact path={from}
            component={() => <RedirectWithStatus to={to} status={status} />} />)}
          <Route exact path={`${checkoutPathPatternPrefix}/checkout`} key="/checkout"
            component={() => <PageShell noPopups titleTag="Checkout" {...pageShellProps}><CheckoutPage /></PageShell>} />
          <Route exact path={`${confirmPathPatternPrefix}/confirm/:pathOrderGuid`} key="/confirm"
            component={() => <PageShell noPopups titleTag="Order Confirmation" {...pageShellProps}><ConfirmationPage /></PageShell>} />
          <Route exact path={`${confirmPathPatternPrefix}/confirm`} key="/confirm"
            component={() => <PageShell noPopups titleTag="Order Confirmation" {...pageShellProps}><ConfirmationPage /></PageShell>} />
          <Route exact path={`${rootPathPatternPrefix}/account`} key="/account" component={() => <PageShell titleTag="My Account" {...pageShellProps}><AccountPage /></PageShell>} />
          <Route exact path="/map" component={() => <MapPage color={primaryColor} siteTheme={theme} />} />
          <Route exact path="/sites-web/v1/map" component={() => <MapPage color={primaryColor} siteTheme={theme} />} />
          <Route exact path="/adyen" component={() => <AdyenPage />} />
          <Route exact path="/sites-web/v1/adyen" component={() => <AdyenPage />} />
          <Route exact path={`${rootPathPatternPrefix}/`} key="/"
            component={() =>
              <PageShell noPopups={!locationPageAtRoot} titleTag={locationPageAtRoot ? 'Location Selection' : 'Order Online'} {...pageShellProps}
                ooUsage={!locationPageAtRoot ? RxDataUsage.Required : RxDataUsage.None}>
                {locationPageAtRoot ? <LocationSelectionPage /> : <OrderPage />}
              </PageShell>} />
          <Route exact path={`${rootPathPatternPrefix}/brandedAppRedirect`} key="/rootBrandedAppRedirect"
            component={() =>
              <PageShell {...pageShellProps}
                ooUsage={RxDataUsage.Optional}>
                <AppRedirect />
              </PageShell>} />
          <Route exact path={`${orderPathPattern}/:itemSlug(item-[^_]+_.+)?`} key="/menuItemModal"
            component={() => <PageShell noPopups titleTag="Order Online" {...pageShellProps}><OrderPage /></PageShell>} />
          <Route exact path={`${orderPathPattern}/add/:guid(.+)/:ignore`} key="/menuItemModalRedirect"
            component={() => <PageShell noPopups titleTag="Order Online" {...pageShellProps}><OrderPage /></PageShell>} />
          <Route exact path={`${orderPathPattern}/:menuSlug([^_]+_)?:menuGuid?`} key="/menuOrder"
            component={() => <PageShell noPopups titleTag="Order Online" {...pageShellProps}><OrderPage /></PageShell>} />
          <Route exact path={orderPathPattern} key="/slugOrder"
            component={() => <PageShell noPopups titleTag="Order Online" {...pageShellProps}><OrderPage /></PageShell>} />
          <Route component={NoMatch404} />
        </Switch>
      </SiftContextProvider>
    </CustomerContextProviderWrapper>
  );
};

// Routing for order.toasttab.com/online
const ToastOrderRoutes = ({ staticContext }: { staticContext?: RequestContext }) => {
  const shortUrl = getSiteShortUrl(staticContext);

  return <OrderRoutes
    staticContext={staticContext}
    sitesUsage={RxDataUsage.Optional}
    rootPathPatternPrefix={`${TOASTTAB_BOO_PATH}/:slug`}
    orderPathPattern={`${TOASTTAB_BOO_PATH}/:slug`}
    orderPath={`${TOASTTAB_BOO_PATH}/${shortUrl}`}
    checkoutPathPatternPrefix={`${TOASTTAB_BOO_PATH}/:slug`}
    checkoutPathPrefix={`${TOASTTAB_BOO_PATH}/${shortUrl}`}
    confirmPathPatternPrefix={`${TOASTTAB_BOO_PATH}/:slug`}
    confirmPathPrefix={`${TOASTTAB_BOO_PATH}/${shortUrl}`}
    locationsPathPatternPrefix={`${TOASTTAB_BOO_LOCATIONS_PATH}/:mgmtGroupGuid/:siteRef?`} />;
};

export default ToastOrderRoutes;
