import React, { useContext, createContext, useCallback, useEffect, useState } from 'react';

import { DeliveryInfoInput, SavedAddress, useValidateDeliveryDistanceLazyQuery, ValidateDeliveryDistanceDocument, ValidateDeliveryDistanceQueryResult } from 'src/apollo/onlineOrdering';
import { useExperiment } from 'src/shared/components/common/ab_testing/ABTestContext';
import { useRestaurant } from 'src/shared/components/common/restaurant_context/RestaurantContext';

import { useOOClient } from 'shared/components/common/oo_client_provider/OOClientProvider';

import { DeliveryInfo } from 'public/components/online_ordering/types';

import { useCustomer } from './CustomerContextCommon';

export type DeliveryContextType = {
  validDeliveryAddress?: DeliveryInfoInput | null;
  clearDeliveryAddress: () => void;
  validateDeliveryAddress: (deliveryInfo: DeliveryInfoInput, restaurantGuid?: string) => Promise<void>;
  deliveryValidationError: string;
  setDeliveryValidationError: (error: string) => void;
  clearDeliveryValidationError: () => void;
  hasCheckedStoredLocation: boolean;
  validateAllDeliveryAddresses: (addresses: SavedAddress[], restaurantGuid?: string) => Promise<void>;
  isValidating: boolean;
  savedAddressUsed: boolean;
  setSavedAddressUsed: (savedAddressUsed: boolean) => void;
  address2: string;
  setAddress2: (address2: string) => void;
};

export const DeliveryContext = createContext<DeliveryContextType | undefined>(undefined);

const getLocalStorageKey = (guid: string | null) => {
  // OOBasic pages don't have a site guid, so this will be shared with all ooBasic restaurants/sites
  return `toast-oo-delivery-location|${guid || 'ooBasic'}`;
};

export const saveDeliveryInfo = (deliveryInfo: DeliveryInfo, guid: string | null) => {
  const key = getLocalStorageKey(guid);
  localStorage.setItem(key, JSON.stringify(deliveryInfo));
};

const getDeliveryInfo = (guid: string | null): DeliveryInfoInput | null => {
  const strInfo = localStorage.getItem(getLocalStorageKey(guid));
  return strInfo ? JSON.parse(strInfo) as DeliveryInfoInput : null;
};

export const DeliveryContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { restaurant, selectedLocation } = useRestaurant();
  const { savedAddressUsed, setSavedAddressUsed } = useCustomer();
  const [isValidating, setIsValidating] = useState(false);
  const [hasCheckedStoredLocation, setHasCheckedStoredLocation] = useState(false);
  const [candidateDeliveryAddress, setCandidateDeliveryAddress] = useState<DeliveryInfoInput>();
  const [validDeliveryAddress, setValidDeliveryAddress] = useState<DeliveryInfoInput | null>();
  const [deliveryValidationError, setDeliveryValidationError] = useState('');
  const [address2, setAddress2Internal] = useState('');
  const { selectedVariant: deliveryAddressTest } = useExperiment('woo-delivery-addr-revision');

  const setAddress2 = useCallback((address2: string ) => {
    setAddress2Internal(address2);
    if(validDeliveryAddress) {
      const updatedAddress: DeliveryInfoInput = { ...validDeliveryAddress };
      if(address2) {
        updatedAddress.address2 = address2;
      }
      setValidDeliveryAddress(updatedAddress);
    }
  }, [validDeliveryAddress]);

  // don't use cache so that useEffect will get triggered
  const [validateDeliveryDistance, { data: deliveryValidationResponse, error: deliveryValidationResponseError }] = useValidateDeliveryDistanceLazyQuery({ fetchPolicy: 'no-cache' });
  const client = useOOClient();


  useEffect(() => {
    if(deliveryValidationResponse) {
      if(deliveryValidationResponse.validateDeliveryLocationV2?.valid) {
        setValidDeliveryAddress(candidateDeliveryAddress);
        if(deliveryAddressTest === 'test') {
          setAddress2Internal(candidateDeliveryAddress?.address2 || '');
        }
        setDeliveryValidationError('');
      } else {
        setValidDeliveryAddress(null);
        setDeliveryValidationError('You\'re out of range for delivery. To start an order, update your delivery address.');
      }
      setIsValidating(false);
    }
    setHasCheckedStoredLocation(true);
  // only run when the delivery address is validated
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryValidationResponse]);

  useEffect(() => {
    if(deliveryValidationResponseError) {
      setValidDeliveryAddress(null);
      setDeliveryValidationError('Sorry, we had trouble verifying your address');
      setIsValidating(false);
    }
  }, [deliveryValidationResponseError]);

  const validateDeliveryAddress = useCallback(async (deliveryInfo: DeliveryInfoInput, restaurantGuid?: string) => {
    if(restaurantGuid && deliveryInfo && !isValidating) {
      setCandidateDeliveryAddress(deliveryInfo);
      setIsValidating(true);
      validateDeliveryDistance({ variables: { input: { deliveryInfo, restaurantGuid } } });
    }
  }, [isValidating, setCandidateDeliveryAddress, setIsValidating, validateDeliveryDistance]);

  const validateAllDeliveryAddresses = useCallback(async (addresses: SavedAddress[], restaurantGuid?: string) => {
    for(const address of addresses) {
      const deliveryInfoInput = { ...address.deliveryInfo, __typename: undefined };
      if(restaurantGuid && deliveryInfoInput && !isValidating) {
        setIsValidating(true);
        const { data } = await client.query<ValidateDeliveryDistanceQueryResult['data']>({
          query: ValidateDeliveryDistanceDocument,
          variables: { input: { deliveryInfo: deliveryInfoInput, restaurantGuid } },
          fetchPolicy: 'no-cache'
        });
        if(data?.validateDeliveryLocationV2.valid) {
          setValidDeliveryAddress(deliveryInfoInput);
          setAddress2Internal(deliveryInfoInput.address2 || '');
          setSavedAddressUsed(true);
          setDeliveryValidationError('');
          setIsValidating(false);
          return;
        }
      }
    }

    // clear any selected address if exists, there are no valid addresses for delivery in gx address book
    setIsValidating(false);
    setValidDeliveryAddress(null);
    if(deliveryAddressTest === 'test') {
      setAddress2Internal('');
    }
    setSavedAddressUsed(false);
  }, [client, isValidating, setSavedAddressUsed, deliveryAddressTest]);

  // when an address is cleared, ensure we also wipe out if it was a saved address
  const clearDeliveryAddress = () => {
    setValidDeliveryAddress(null);
    if(deliveryAddressTest === 'test') {
      setAddress2Internal('');
    }
    setSavedAddressUsed(false);
  };

  useEffect(() => {
    if(typeof window !== 'undefined') {
      const storedDeliveryInfo = getDeliveryInfo(restaurant.externalId);
      if(storedDeliveryInfo && selectedLocation.externalId) {
        validateDeliveryAddress(storedDeliveryInfo, selectedLocation.externalId);
      }
      setHasCheckedStoredLocation(true);
    }
  // We don't want the `validateDeliveryAddress` callback to trigger another call
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation, restaurant]);

  return (
    <DeliveryContext.Provider value={{
      hasCheckedStoredLocation,
      validDeliveryAddress,
      clearDeliveryAddress,
      validateDeliveryAddress,
      deliveryValidationError,
      setDeliveryValidationError,
      validateAllDeliveryAddresses,
      clearDeliveryValidationError: () => setDeliveryValidationError(''),
      isValidating,
      savedAddressUsed,
      setSavedAddressUsed,
      address2,
      setAddress2
    }}>
      {props.children}
    </DeliveryContext.Provider>
  );
};

export const useDelivery = () => {
  const context = useContext(DeliveryContext);
  if(!context) {
    throw new Error('useDelivery must be used within a DeliveryContextProvider');
  }

  return context;
};
