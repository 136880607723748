import { DoMenus_GetMenuItemsItem as DoMenusGetMenuItemsItem } from 'src/apollo/onlineOrdering';

import { MenuItemBlockBackupContent } from 'shared/components/common/dynamic_menus/menu_item/MenuItem';


// This is needed for backwards compatibility
export const getItemImage = (item: DoMenusGetMenuItemsItem | MenuItemBlockBackupContent) => {
  if('imageLink' in item && item.imageLink) {
    if(item?.imageLink.startsWith('https://toast-stage.s3.amazonaws.com/')) {
      return item?.imageLink.replace(
        'https://toast-stage.s3.amazonaws.com/',
        'https://s3.amazonaws.com/toasttab/'
      );
    }
    return item?.imageLink;
  } else if('imageSrc' in item && item.imageSrc) {
    return item.imageSrc;
  }
  return '';
};
